var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-row', [_c('v-col', [_c('div', {
    staticClass: "page--new-password d-flex justify-center align-center"
  }, [_c('div', {
    staticClass: "form-container"
  }, [_c('v-card', {
    staticClass: "pa-10",
    attrs: {
      "color": "rgba(255, 255, 255, .8)"
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center"
  }, [_c('img', {
    attrs: {
      "src": "/img/brasao_64.png",
      "alt": "Brasão do state de Pernambuco"
    }
  }), _c('br'), _c('h1', {
    staticClass: "subtitle-1 blue--text text--darken-4"
  }, [_vm._v(" Nova Senha ")])])], 1), _c('v-row', [_c('v-col', [_c('v-text-field', {
    attrs: {
      "solo": "",
      "hide-details": "auto",
      "placeholder": "Nova senha",
      "type": "password",
      "rules": _vm.passwordRules,
      "disabled": !_vm.isAccessValid
    },
    model: {
      value: _vm.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.user, "password", $$v);
      },
      expression: "user.password"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "success",
      "loading": _vm.loading,
      "disabled": !_vm.isAccessValid
    },
    on: {
      "click": function ($event) {
        return _vm.changePassword();
      }
    }
  }, [_vm._v(" Mudar Senha ")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0 mt-3 text-center"
  }, [_c('small', [_c('router-link', {
    attrs: {
      "to": {
        path: '/EsqueciASenha'
      }
    }
  }, [_vm._v(" Esqueci a senha ")])], 1)])], 1)], 1)], 1)], 1)])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }